<template>
    <Head title="Файлы"/>

    <BreezeAdminLayout>
        <template #header-title>
            <h1 class="page_title">Файлы</h1>
        </template>

        <div class="dash__board__container">
            <div class="main_container">

                <div class="filter-panel">
                    <div class="filter-panel__inner">
                        <div class="input input_combo input_combo_2">
                            <div class="input__inner">
                                <input id="selectSearchProductRedeems"
                                       v-model="filterQuery.email"
                                       class="input__text-input"
                                       placeholder="Поиск" type="text"
                                       v-on:keyup.enter="searchStart()">
                                <div class="input__actions">
                                    <button v-if="filterQuery.email" aria-label="Сбросить" class="input__btn fm-x"
                                            type="button"
                                            @click="clearFilterSearch()"></button>
                                    <button aria-label="Искать" class="input__btn fm-search" type="button"
                                            @click="searchStart()"></button>
                                </div>
                            </div>
                        </div>

                        <button class="btn-default ml-4 btn-regular" type="button"
                                @click="clearFilter()">
                            Очистить фильтр
                        </button>

                    </div>
                </div>

                <div class="table_outer">
                    <div class="expenses-wrapper">
                        <table v-if="users"
                               class="table table-fixed table-fw-widget partners-table table-condensed dashboard-table admin-users-table">
                            <thead>
                            <tr>
                                <th class="nowrap" style="text-align: left;">
                                    Почта
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(user, key) in users" :key="key">
                                <UserRow :user="user"/>
                            </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table-last-section" v-if="links.length > 3">
                    <span v-for="link in links">
                       <a v-if="link.url || link.label === '...'"
                          :class="link.active ? 'text-gray-700' : ''"
                          :href="link.url"
                          class="mx-2" @click="selectPage(link.url)" v-html="link.label">
                       </a>
                    </span>
                </div>
            </div>
        </div>

    </BreezeAdminLayout>
</template>
<script>

import BreezeAdminLayout from "@/Layouts/Admin.vue";
import UserRow from "@/Pages/Admin/Files/Components/UserRow.vue";
import Input from "@/Components/Input.vue";
import {Inertia} from "@inertiajs/inertia";
import {pickBy} from "lodash-es";

export default {
    components: {Input, UserRow, BreezeAdminLayout},

    props: {
        users: {
            type: Array,
            required: true
        },
        email: {
            type: String,
            required: false
        },
        links: {
            type: Array,
            required: true
        },
    },

    data() {
        return {
            filterQuery: {
                email: '',
            },
        }
    },

    mounted() {
        this.filterQuery.email = this.email;
    },

    methods: {
        searchStart() {
            Inertia.get(route('admin.files.index'), pickBy(this.filterQuery));
        },
        clearFilterSearch() {
            this.filterQuery.email = '';
            this.searchStart();
        },
        clearFilter() {
            this.filterQuery.email = '';
            this.searchStart();
        },

        selectPage(url) {
            if (url === '...') {
                return;
            }
            this.$inertia.get(url);
        },
    },


}

</script>
