<template>
    <Modal
        v-if="show"
        @close="$emit('close')"
        modalClasses="modal-basic unsubModal"
    >
        <template #title>
            <h3 class="h4 modal-title">Отмена подписки</h3>
        </template>
        <template #body>
            <div class="unsubModalBlock" v-if="activeScreen === 'first'">
                <div class="unsubModalBlock__section">
                    <h4 class="unsubModalBlock__title">
                        <span class="unsubModalBlock__ico fm-alert-triangle"></span>
                        Предупреждение!
                    </h4>
                    <p>Ваша учетная запись останется активной до конца оплаченного периода ({{ subscriptionEndDate }}),
                        а затем будет деактивирована. Ваши финансовые данные будут удалены автоматически через 65 дней
                        после деактивации, если вы повторно не активируете подписку.</p>
                </div>
                <hr>
                <div class="unsubModalBlock__section">
                    <h5 class="unsubModalBlock__smallTitle">Ниже представлены данные, которые будут безвозвратно удалены
                        из нашей системы:</h5>
                    <ul>
                        <li>Данные о продажах</li>
                        <li>Данные о заказах</li>
                        <li>Себестоимости товаров</li>
                        <li>Расходы, о которых не знает маркетплейс</li>
                        <li>Расходы на рекламу</li>
                        <li>Данные о самовыкупах</li>
                        <li>Складской учет</li>
                    </ul>
                </div>

                <div class="unsubModalBlock__panel">
                    <h5 class="unsubModalBlock__smallTitle">Ваша текущая подписка действительна до:
                        {{ subscriptionEndDate }}</h5>
                    <p>Вы уверены, что хотите отменить подписку?</p>
                </div>
            </div>

            <div class="unsubModalBlock" v-if="activeScreen === 'second'">
                <div class="unsubModalBlock__section">
                    <h4 class="unsubModalBlock__title">Очень жаль с вами расставаться</h4>
                    <p>Подскажите, пожалуйста, почему вы хотите прекратить пользование сервисом sellerdata:</p>
                </div>
                <form class="unsubModalBlock__section" action="">
                    <div class="input" v-for="(value,key) in types">
                        <div class="input__inner" v-if="key != 'not_provided'">
                            <input class="input__radio" v-model="selectedReason" :value="key" :id="key" type="radio"
                                   name="reason">
                            <label class="input__radio-label" :for="key">
                                {{ value }}
                            </label>
                        </div>
                    </div>

                    <div class="input" v-if="selectedReason === 'other'">
                        <div class="input__inner">
                            <label class="input__label visually-hidden" for="unsubReason-9">Другое</label>
                            <input class="input__text-input" v-model="message" id="unsubReason-9" type="text"
                                   placeholder="Напишите свою причину">
                        </div>
                    </div>
                </form>
            </div>


        </template>
        <template #buttons>
            <button v-if="activeScreen === 'first'" class="btn-default" type="button" @click="changeScreen('second')">
                Отменить подписку
            </button>
            <button v-if="activeScreen === 'second'" class="btn-default" type="button" @click="unsubscribe()">Отменить
                подписку
            </button>
            <button class="btn-default btn-regular" type="button" @click="saveSubscription()">Сохранить подписку
            </button>
        </template>
    </Modal>

    <Modal
        v-if="showCanceledModal"
        @close="showCanceledModal = !showCanceledModal"
        footerClasses="modal-footer_center"
    >
        <template #title>
            <h3 class="h4 modal-title">Отмена подписки</h3>
        </template>
        <template #body>
            <div class="unsubModalBlock">
                <div class="unsubModalBlock__section">
                    <h4 class="unsubModalBlock__title">Ваша подписка отменена</h4>
                    <p>Спасибо за использование нашего сервиса!</p>
                </div>
                <div class="unsubModalBlock__panel">
                    <h5 class="unsubModalBlock__smallTitle">Ваша текущая подписка действительна до:</h5>
                    <p>{{ subscriptionEndDate }}</p>
                </div>
            </div>
        </template>
        <template #buttons>
            <button class="btn-default btn-regular" @click="gotInformed()">Понятно</button>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/Components/Common/Modal.vue";
import Button from "@/Components/Button.vue";

export default {
    components: {Button, Modal},
    props: ['show', 'subscriptionEndDate', 'types'],
    emits: ['close', 'unsubscribe'],
    data() {
        return {
            activeScreen: 'first',
            showCanceledModal: false,
            selectedReason: null,
            message: null,
        }
    },
    methods: {
        changeScreen(screen) {
            this.activeScreen = screen;
        },
        unsubscribe() {
            this.$emit('close');
            this.$emit('unsubscribe', this.selectedReason ?? 'not_provided', this.message ?? null);
            this.showCanceledModal = !this.showCanceledModal;
        },
        saveSubscription() {
            this.changeScreen('first');
            this.$emit('close');
        },
        gotInformed() {
            this.showCanceledModal = !this.showCanceledModal;
            document.location.href = "/pricing";
        }
    },
};
</script>
