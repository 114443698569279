<template>

        <ul class="info-list__accordion">
            <li class="info-list__accordion__group" :class="{ 'active': salesOpened }">
                <div class="info-list__accordion__item info-list__accordion__item_header info-list__accordion__item_btn">
                    <button
                        class="info-list__accordion__toggle-btn"
                        type="button"
                        aria-label="toggle"
                        @click="salesOpened = !salesOpened"
                    />
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.realSalesAmount')">Продажи</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.realSalesAmount"/></span>
                    </div>
                </div>
                <div class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.quantity')">Количество товаров</span>
                        <span class="info-list__accordion__value">{{ summary.quantity }}</span>
                    </div>
                </div>
                <div class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.salesAmount')">Продажи со скидкой</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.salesAmount"/></span>
                    </div>
                </div>
                <div v-if="summary.stornoRefundAmount" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.stornoRefundAmount')">Сторно возвратов</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.stornoRefundAmount"/></span>
                    </div>
                </div>
                <div v-if="summary.defectPayment" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.defectPayment')">Оплата брака</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.defectPayment"/></span>
                    </div>
                </div>
                <div v-if="summary.partDefectPayment" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.partDefectPayment')">Частичная компенсация брака</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.partDefectPayment"/></span>
                    </div>
                </div>
                <div v-if="summary.lostGoodsSalesAmount" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.lostGoodsSalesAmount')">Оплата потерянного товара</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.lostGoodsSalesAmount"/></span>
                    </div>
                </div>
                <div v-if="summary.inventoryPayment" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.inventoryPayment')">Оплата по итогам инвентаризации</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.inventoryPayment"/></span>
                    </div>
                </div>
                <div v-if="summary.compensationForSubstitutedGoods" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.compensationForSubstitutedGoods')">Компенсация за подмененные товары</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.compensationForSubstitutedGoods"/></span>
                    </div>
                </div>
                <div v-if="summary.redeemsSales" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.redeems_goods_price')">Самовыкупы</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.redeemsSales"/></span>
                    </div>
                </div>
                <div v-if="summary.goodsWithNoMovement" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.goodsWithNoMovement')">Авансовая оплата за товар без движения</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.goodsWithNoMovement"/></span>
                    </div>
                </div>
                <div v-if="summary.salesCorrection" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.salesCorrection')">Коррекции</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.salesCorrection"/></span>
                    </div>
                </div>
            </li>


            <li class="info-list__accordion__group" :class="{ 'active': refundsOpened }">
                <div class="info-list__accordion__item info-list__accordion__item_header info-list__accordion__item_btn">
                    <button
                        class="info-list__accordion__toggle-btn"
                        type="button"
                        aria-label="toggle"
                        @click="refundsOpened = !refundsOpened"
                    />
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.refundsAmount')">Возвраты</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.refundsAmount"/></span>
                    </div>
                </div>
                <div class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.refunds')">Количество возвратов</span>
                        <span class="info-list__accordion__value">{{ summary.refundQuantity }}</span>
                    </div>
                </div>
                <div v-if="summary.refundCompensationForSubstitutedGoods" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.refundCompensationForSubstitutedGoods')">Компенсация за подмененные товары</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.refundCompensationForSubstitutedGoods"/></span>
                    </div>
                </div>
                <div class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.clientReward')">Возмещение клиентам</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.clientReward"/></span>
                    </div>
                </div>
                <div v-if="summary.stornoSalesAmount" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.stornoSalesAmount')">Сторно продаж</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.stornoSalesAmount"/></span>
                    </div>
                </div>

                <div v-if="summary.refundPartDefectPayment" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.refundPartDefectPayment')">Частичная компенсация брака</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.refundPartDefectPayment"/></span>
                    </div>
                </div>
                <div v-if="summary.refundGoodsWithNoMovement" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.refundGoodsWithNoMovement')">Авансовая оплата за товар без движения</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.refundGoodsWithNoMovement"/></span>
                    </div>
                </div>
                <div v-if="summary.defectPaymentRefund" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.defectPaymentRefund')">Оплата брака</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.defectPaymentRefund"/></span>
                    </div>
                </div>
                <div v-if="summary.lostGoodsRefund" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.lostGoodsRefund')">Оплата потерянного товара</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.lostGoodsRefund"/></span>
                    </div>
                </div>
                <div v-if="summary.redeemsRefunds" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.redeemsRefunds')">Самовыкупы</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.redeemsRefunds"/></span>
                    </div>
                </div>
                <div v-if="summary.refundsCorrection" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.refundsCorrection')">Коррекции</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.refundsCorrection"/></span>
                    </div>
                </div>
            </li>

            <li class="info-list__accordion__group" :class="{ 'active': wbOpened }">
                <div class="info-list__accordion__item info-list__accordion__item_header info-list__accordion__item_btn">
                    <button
                        class="info-list__accordion__toggle-btn"
                        type="button"
                        aria-label="toggle"
                        @click="wbOpened = !wbOpened"
                    />
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.deduction')">Удержания</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.deduction"/></span>
                    </div>
                </div>
                <div v-if="summary.acquiring" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.acquiring')">Эквайринг</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.acquiring"/></span>
                    </div>
                </div>
                <div v-if="summary.penalty" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.penalty')">Штрафы</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.penalty"/></span>
                    </div>
                </div>
                <div v-if="summary.penalty_return" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.penalty_return')">Аннулирование штрафов</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.penalty_return"/></span>
                    </div>
                </div>
                <div v-if="summary.additional_payment" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.additional_payment')">Доплаты</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.additional_payment"/></span>
                    </div>
                </div>
                <div v-if="summary.additional_payment_return" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.additional_payment_return')">Аннулирование доплат</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.additional_payment_return"/></span>
                    </div>
                </div>
                <div class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.logisticCost')">Доставка (Логистика)</span>
                        <button v-if="!summary.stable" class="info-list__accordion__ico info-list__accordion__ico_warning fm-info" type="button" v-tippy="__('hints.dashboard.warningInfoLogistic')" aria-label="info"></button>
                        <span class="info-list__accordion__value"><Currency :value="summary.logisticCost"/></span>
                    </div>
                </div>
                <div v-if="summary.logisticSellerReturn" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.logisticSellerReturn')">Возврат товара поставщику</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.logisticSellerReturn"/></span>
                    </div>
                </div>
                <div v-if="summary.deliveryCompensation" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.deliveryCompensation')">Компенсация доставки до клиента</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.deliveryCompensation"/></span>
                    </div>
                </div>
                <div v-if="summary.stornoDelivery" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.logisticCost')">Сторно доставки</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.stornoDelivery"/></span>
                    </div>
                </div>
                <div class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.commission')">Комиссия</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.commission"/></span>
                    </div>
                </div>
                <div v-if="summary.stornoCommission" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.stornoCommission')">Сторно комиссии</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.stornoCommission"/></span>
                    </div>
                </div>
                <div v-if="summary.expensesStorage" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.storageWB')">Хранение</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.expensesStorage"/></span>
                    </div>
                </div>
                <div v-if="summary.expensesAcceptance" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.acceptanceWb')">Приемка</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.expensesAcceptance"/></span>
                    </div>
                </div>
                <div v-if="summary.expensesOthers" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span v-if="$page.props.auth.user.user_account.account.data_source === 'ozon'" class="info-list__accordion__title" v-tippy="__('hints.dashboard.otherOZON')">Прочие</span>
                        <span v-else class="info-list__accordion__title" v-tippy="__('hints.dashboard.otherWB')">Прочие</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.expensesOthers"/></span>
                    </div>
                </div>
                <div v-if="summary.redeemsExpenses" class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.redeemsExpenses')">Расходы на самовыкупы</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.redeemsExpenses"/></span>
                    </div>
                </div>
            </li>

            <li v-if="store.debug" class="info-list__accordion__group" :class="{ 'active': debugPane }">
                <div class="info-list__accordion__item info-list__accordion__item_header info-list__accordion__item_btn">
                    <button
                        class="info-list__accordion__toggle-btn"
                        type="button"
                        aria-label="toggle"
                        @click="debugPane = !debugPane"
                    />
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title">Дебаг</span>
                        <span class="info-list__accordion__value"><Currency value="0"/></span>
                    </div>
                </div>
                <div class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title">Приемка</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.debugAcceptance"/></span>
                    </div>
                </div>
                <div class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.penalty')">Хранение</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.debugStorage"/></span>
                    </div>
                </div>
                <div class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title">Реклама</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.debugAdvertisement"/></span>
                    </div>
                </div>
                <div class="info-list__accordion__item">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title">Реклама</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.debugAdvertisementFull"/></span>
                    </div>
                </div>
            </li>

            <li v-if="summary.advertisement" class="info-list__accordion__group">
                <div class="info-list__accordion__item info-list__accordion__item_header">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.campaign')">Реклама</span>
<!--                        <button  :class="['info-list__accordion__ico fm-info', !summary.isCampaignExpensesSynced ? 'info-list__accordion__ico_warning' : '']"  v-tippy="tooltipContent" aria-label="info"></button>-->
                        <span class="info-list__accordion__value"><Currency :value="summary.advertisement"/></span>
                    </div>
                </div>
            </li>

            <li class="info-list__accordion__group">
                <div class="info-list__accordion__item info-list__accordion__item_header">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.goodsCost')">Себестоимость</span>
                        <button v-show="!summary.goodsCost" class="info-list__accordion__ico info-list__accordion__ico_warning fm-info" type="button" v-tippy="__('hints.dashboard.deductionWarning')" aria-label="info"></button>
                        <span class="info-list__accordion__value"><Currency :value="summary.goodsCost"/></span>
                    </div>
                </div>
            </li>

            <li class="info-list__accordion__group">
                <div class="info-list__accordion__item info-list__accordion__item_header">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.grossProfit')">Валовая прибыль</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.grossProfit"/></span>
                    </div>
                </div>
            </li>

            <li v-if="summary.redeems" class="info-list__accordion__group">
                <div class="info-list__accordion__item info-list__accordion__item_header">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.redeems')">Услуги самовыкупа</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.redeems"/></span>
                    </div>
                </div>
            </li>

            <li class="info-list__accordion__group">
                <div class="info-list__accordion__item info-list__accordion__item_header">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.expenses')">Расходы</span>
                        <span class="info-list__accordion__value"><Currency :value="summary.expenses"/></span>
                    </div>
                </div>
            </li>

            <li class="info-list__accordion__group">
                <div class="info-list__accordion__item info-list__accordion__item_header">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.tax') + ' ' + summary.taxFrom + ' ₽'">Налог на прибыль</span>
                        <button v-show="!summary.tax" class="info-list__accordion__ico fm-info info-list__accordion__ico_warning" type="button" v-tippy="__('hints.dashboard.taxWarning')" aria-label="info"></button>
                        <span class="info-list__accordion__value"><Currency :value="summary.tax"/></span>
                    </div>
                </div>
            </li>

            <li class="info-list__accordion__group">
                <div class="info-list__accordion__item info-list__accordion__item_header">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.vat') + ' ' + summary.vatFrom + ' ₽'">НДС</span>
                        <button v-if="!$page.props.auth.user.user_account.account.vat_enabled" class="info-list__accordion__ico fm-info info-list__accordion__ico_warning" type="button" v-tippy="__('hints.dashboard.vatWarning')" aria-label="info"></button>
                        <span class="info-list__accordion__value"><Currency :value="summary.vat"/></span>
                    </div>
                </div>
            </li>

            <li class="info-list__accordion__group info-list__accordion__group_highlighted">
                <div class="info-list__accordion__item info-list__accordion__item_header">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.profit')">Прибыль</span>
                        <button
                            class="info-list__accordion__ico info-list__accordion__ico_warning fm-info"
                            type="button"
                            aria-label="info"
                            v-if="showProfitWarning"
                            v-tippy="profitWarning"
                        />
                        <span class="info-list__accordion__value"><Currency :value="summary.profit"/></span>
                    </div>
                </div>
            </li>
            <li class="info-list__accordion__group">
                <div class="info-list__accordion__item info-list__accordion__item_header">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.payout')">Сумма выплат</span>
                        <button
                            class="info-list__accordion__ico info-list__accordion__ico_warning fm-info"
                            type="button"
                            aria-label="info"
                            v-if="showPayoutWarning"
                            v-tippy="payoutWarning"
                        />
                        <span class="info-list__accordion__value"><Currency :value="summary.payout"/></span>
                    </div>
                </div>
            </li>

            <li class="info-list__accordion__group info-list__accordion__group_bg">
                <div class="info-list__accordion__item info-list__accordion__item_header">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.margin')">Маржа</span>
                        <button v-show="!summary.quantity" class="info-list__accordion__ico info-list__accordion__ico_warning fm-info" type="button" v-tippy="__('hints.dashboard.warningInfoMargin')" aria-label="info"></button>
                        <span v-if="summary.quantity" class="info-list__accordion__value"><Percent :value="margin"/></span>
                        <span v-else class="info-list__accordion__value"><span class="info-list__accordion__no-data">&ndash;</span></span>
                    </div>
                </div>
            </li>

            <li class="info-list__accordion__group info-list__accordion__group_bg">
                <div class="info-list__accordion__item info-list__accordion__item_header">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.roi')">ROI</span>
                        <button
                            v-if="!summary.goodsCost"
                            class="info-list__accordion__ico info-list__accordion__ico_warning fm-info"
                            type="button"
                            v-tippy="__('hints.dashboard.roiWarning')"
                            aria-label="info">
                        </button>
                        <button
                            v-else-if="!summary.quantity"
                            class="info-list__accordion__ico info-list__accordion__ico_warning fm-info"
                            type="button"
                            v-tippy="__('hints.dashboard.warningInfoRoi')"
                            aria-label="info">
                        </button><span v-if="summary.quantity" class="info-list__accordion__value"><Percent :value="roi"/></span>
                        <span v-else class="info-list__accordion__value"><span class="info-list__accordion__no-data">&ndash;</span></span>
                    </div>
                </div>
            </li>

            <li class="info-list__accordion__group info-list__accordion__group_bg">
                <div class="info-list__accordion__item info-list__accordion__item_header">
                    <div class="info-list__accordion__inner">
                        <span class="info-list__accordion__title" v-tippy="__('hints.dashboard.redemption')">Выкупаемость</span>
                        <button v-if="!summary.quantity || !summary.stable" class="info-list__accordion__ico info-list__accordion__ico_warning fm-info" type="button" v-tippy="__('hints.dashboard.warningInfoRedemption')" aria-label="info"></button>
                        <span v-if="summary.quantity && summary.stable" class="info-list__accordion__value"><Percent :value="summary.redemption"/></span>
                        <span v-else class="info-list__accordion__value"><span class="info-list__accordion__no-data">&ndash;</span></span>
                    </div>
                </div>
            </li>
        </ul>


</template>

<script>
import Percent from '@/Components/Common/Percent.vue'
import Currency from '@/Components/Common/Currency.vue'
import { store } from '@/store'

export default {
    name: 'TransactionSummary',
    components: { Currency, Percent },
    props: {
        summary: {
            type: Object,
            required: true
        },
        showPayoutWarning: {
            type: Boolean,
            default: false
        },
        payoutWarning: {
            type: String,
            default: ''
        },
        showProfitWarning: {
            type: Boolean,
            default: false
        },
        profitWarning: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            salesOpened: false,
            refundsOpened: false,
            wbOpened: false,
            debugPane: false,
            store
        }
    },
    computed: {
        margin() {
            const income = this.summary.realSalesAmount + this.summary.refundsAmount;

            if (income == 0) {
                return 0;
            }

            return Math.round(this.summary.profit / income * 100);
        },
        roi() {
            if (this.summary.goodsCost == 0) {
                return 0;
            }

            return Math.round(this.summary.profit / Math.abs(this.summary.goodsCost) * 100);
        },
        tooltipContent() {
            return this.summary.isCampaignExpensesSynced
                ? this.__('hints.dashboard.expensesCampaign', {fullStatsAmount: this.summary.expensesCampaignFullAmount})
                : `${this.__('hints.dashboard.expensesCampaignAlert')}<hr>${this.__('hints.dashboard.expensesCampaign', {fullStatsAmount: this.summary.expensesCampaignFullAmount})}`;
        }
    }
}
</script>
